import { DefaultSeo } from 'next-seo';
import '@pocketoutdoormedia/web-microfrontends.styles.global-styles/src/_index.css';
import '@styles/globals.css';
import type { AppContext, AppInitialProps } from 'next/app';
import WatchSubNavigation from 'components/03-organisms/WatchSubNavigation';
import { useRouter } from 'next/router';
import ReactQueryProvider from 'providers/ReactQuery';
import Scripts from 'scripts';
import PortalRendererContextProvider from 'providers/PortalRenderer';
import CacheProvider from 'providers/CacheProvider';
import Footer from 'components/03-organisms/Footer';
import {
  authServiceFactory,
  AuthServiceProvider
} from '@pocketoutdoormedia/auth-state-service';
import { usePianoStoreUpdaterContext } from 'contexts/PianoStoreContext';
import { InitDatadog } from 'helpers/utils/init-datadog/init-datadog';
import { IdentifyAnalyticsService } from 'helpers/hooks/useIdentifyAnalytics';
import { PREVIEW_DOM_ID } from 'components/02-molecules/Preview';
import ErrorBoundary from 'components/03-organisms/ErrorBoundary';
import { useEvent } from 'react-use';
import { piano } from 'lib/piano';
import type { SetResponseVariableEventPayload } from 'lib/piano/types';
import { withPianoStoreProvider } from 'hocs/withPianoStoreProvider';
import WatchRegWallProvider from 'providers/WatchRegWallProvider';
import { ToastContainer } from 'components/01-atoms/Toast';
import { withAnalyticsProvider } from 'hocs/withAnalyticsProvider';
import Header from 'components/03-organisms/Header';

import datadogKeys from '../datadog.config';

if (process.env.NODE_ENV === 'production') {
  InitDatadog(datadogKeys);
}

const OutsideWatch = ({
  Component,
  pageProps
}: AppContext & AppInitialProps) => {
  const router = useRouter();
  const setPianoStore = usePianoStoreUpdaterContext();

  useEvent(
    'setResponseVariable',
    ({ detail }: CustomEvent<SetResponseVariableEventPayload>) => {
      if (detail.responseVariables.pbcTimeLimit) {
        const timeLimit = Number(detail.responseVariables.pbcTimeLimit);

        if (Number.isNaN(timeLimit) || timeLimit < 0) {
          // eslint-disable-next-line no-console
          console.error('Time limit (pbcTimeLimit) is in incorrect format');
          return;
        }

        setPianoStore((prevState) => ({ ...prevState, timeLimit }));
      }
    },
    piano
  );

  const seoProps = {
    description:
      'Outside TV is the leader in Adventure Sports and Outdoor Lifestyle Movies, Series &amp; Shorts. Providing an all access pass to the best videos in Ski, Bike, Climb, Hike, Surf, Adventure Travel and More.',
    openGraph: {
      description:
        'Outside TV is the leader in Adventure Sports and Outdoor Lifestyle Movies, Series &amp; Shorts. Providing an all access pass to the best videos in Ski, Bike, Climb, Hike, Surf, Adventure Travel and More. ',
      locale: 'en_US',
      site_name: 'Outside TV',
      title: 'Outside TV',
      type: 'webpage',
      url: `https://watch.outsideonline.com${router.pathname}`
    },
    titleTemplate: '%s | Outside TV',
    twitter: {
      cardType: 'summary_large_image'
    }
  };

  const authService = authServiceFactory();

  return (
    <ErrorBoundary
      errorDetails={{
        location: 'global'
      }}
    >
      <AuthServiceProvider value={authService}>
        {/* watch-regwall */}
        <WatchRegWallProvider>
          <IdentifyAnalyticsService />
          <ReactQueryProvider dehydratedState={pageProps.dehydratedState}>
            <CacheProvider>
              <PortalRendererContextProvider>
                <Header />
                <ToastContainer />
                <DefaultSeo {...seoProps} />
                <WatchSubNavigation
                  ariaLabel="Main Navigation"
                  currentPath={router.pathname}
                />
                <main>
                  <Component {...pageProps} />
                </main>
                <Footer />
                {/* This specific portal container has to be on the same level in DOM as the Header component
                  so it does not take precedence over it in terms of stacking */}
                <div id={PREVIEW_DOM_ID} />
              </PortalRendererContextProvider>
            </CacheProvider>
          </ReactQueryProvider>
        </WatchRegWallProvider>
        <Scripts />
      </AuthServiceProvider>
    </ErrorBoundary>
  );
};

export default withAnalyticsProvider(withPianoStoreProvider(OutsideWatch));
