import { useQuery } from '@tanstack/react-query';

import { getUserProfile } from './queryFunction';
import type { ExtendedRawUserProfile } from './types';

export const USER_PROFILE_QUERY_KEY = 'userProfile';

export const useUserProfile = (
  rivtToken: string,
  placeholderData?: ExtendedRawUserProfile
) =>
  useQuery({
    enabled: !!rivtToken,
    placeholderData,
    queryFn: () => getUserProfile(rivtToken).then((response) => response.data),
    queryKey: [USER_PROFILE_QUERY_KEY, rivtToken]
  });
