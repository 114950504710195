import { MEMBERSHIP_TYPES, RIVT_ROLES } from 'helpers/constants/rivt';

export const getUserMembership = (
  roles: string
): ValueOf<typeof MEMBERSHIP_TYPES> => {
  if (roles.includes(RIVT_ROLES.Oplus)) return MEMBERSHIP_TYPES.Oplus;
  if (roles.includes(RIVT_ROLES.Paid)) return MEMBERSHIP_TYPES.Paid;
  if (roles.includes(RIVT_ROLES.GaiaGpsPremium))
    return MEMBERSHIP_TYPES.GaiaGpsPremium;
  if (roles.includes(RIVT_ROLES.TrailforksPro))
    return MEMBERSHIP_TYPES.TrailforksPro;

  return MEMBERSHIP_TYPES.Free;
};
