import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import clsx from 'clsx';

const FooterWC = dynamic(() => import('./FooterWC'), { ssr: false });

const Footer = () => {
  const { asPath } = useRouter();

  return (
    <div
      className={clsx(
        'h-[var(--outside-footer-height)] w-full bg-foreground-default'
      )}
    >
      {/* `key` prop added to re-render the footer on path change to re-render and close accordions on mobile */}
      <FooterWC key={asPath} />
    </div>
  );
};

export default Footer;
