export const RIVT_ROLES = {
  Free: 'free_membership',
  GaiaGpsPremium: 'gaia_gps_premium',
  Oplus: 'oplus',
  Paid: 'paid_membership',
  TrailforksPro: 'trailforks_pro'
} as const;

export const MEMBERSHIP_TYPES = {
  Free: 'Free',
  GaiaGpsPremium: 'Gaia GPS Premium',
  Oplus: 'Outside+',
  Paid: 'Outside+',
  TrailforksPro: 'Trailforks Pro'
} as const;
